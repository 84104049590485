import React, { useEffect, useRef, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, Grid, TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { Pagination } from '@material-ui/lab';
import { ButtonTemplate } from '../../components/button/button-template';
import MessageDialog from '../../components/dialog/message-dialog';
import { pluralize } from '../../lib/utils';
import { ActivateUser, ListUserAdmin } from '../../lib/graphql-command/user-command';
import CloseIcon from '@material-ui/icons/Close';

const useStyle = makeStyles(() => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    date: {
        whiteSpace: 'break-spaces'
    },
    links: {
        display: 'none',
        minHeight: '5px',
        '& a': {
            marginRight: '5px',
            textDecoration: 'none',
            fontSize: '0.9rem'
        }
    },
    trash: {
        color: '#a00'
    },
    row: {
        verticalAlign: 'top',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
    searchBtn: {
        borderRadius: '50px',
        marginLeft: '10px'
	},
	videoTitle: {
		width: '40%'
	},
	image: {
		width: '60px',
		height: '60px',
		objectFit: 'cover'
	},
	link: {
		textDecoration: 'none'
	},
	avatar: {
		width: '60px',
		height: '60px',
		objectFit: 'cover'
	},
	username: {
		verticalAlign: 'top'
    },
    loading: {
        opacity: '0.5',
        pointerEvents: 'none',
        position: 'relative'
    },
    loadingIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    textIcon: {
        cursor: 'pointer'
    }
}))

const UserConfirmation = () => {
    const classes = useStyle();
    const [users, setUsers] = useState();
    const [messageDialog, setMessageDialog] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({FilterText: ''});
    const [page, setPage] = useState(1)
    const searchRef = useRef();
    const pageSize = 50

    useEffect(() => {
        getListUser();
    }, [page, search])

    const getListUser = () => {
        setLoading(true)
        API.graphql(graphqlOperation(ListUserAdmin, {
            Limit: pageSize,
            Page: page,
            Search: search.FilterText,
            Filter: 'inactive'
        }))
        .then(result => {
            setUsers(result.data.listUserAdmin)
            setLoading(false)
        })
        .catch(err => console.log(err))
    }

    const handleSearch = () => {
        setPage(1)
        setSearch({
			FilterText: searchRef.current.value || ''
		})
	}

	const handleActivate = user => {
        setLoading(true)
        API.graphql(graphqlOperation(ActivateUser, {UserID: user.UserID}))
        .then(() => {
            setMessageDialog('This account has been successfully activated')
            getListUser()
        })
        .catch(err => console.log(err))
	}

    const pageCount = () => {
        return Math.ceil((users?.Count?.Inactive||0)/pageSize)
    }

    console.log(users)
    return (
        <Box>
            <Typography variant='h4'>Confirmation Link History</Typography>
			<Box py={10}>
				<Grid container justify='flex-end' spacing={10}>
					<Grid item>
						<Box>
							<TextField variant='outlined'
                                size='small'
                                placeholder='Email or Username'
                                inputRef={searchRef}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        handleSearch();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            className={classes.textIcon}
                                            onClick={e => {
                                                searchRef.current.value = ''
                                                handleSearch()
                                            }}
                                        >
                                            <CloseIcon />
                                        </InputAdornment>
                                    ),
                                }}
							/>
							<ButtonTemplate variant='contained'
								color='primary'
								borderradius='50px'
								margin='0 0 0 10px'
								onClick={handleSearch}
							>Search</ButtonTemplate>
						</Box>
					</Grid>
				</Grid>
			</Box>
            <Box className={loading ? classes.loading : ''}>
                {loading && <Box className={classes.loadingIcon}><CircularProgress size={40} /></Box>}
                {users?.Items?.length > 0 && <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Serial No</TableCell>
                                <TableCell>User Email</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.Items?.map((user, index) => (
                                <TableRow key={user.UserID} className={classes.row}>
                                    <TableCell>{pageSize * (page-1) + index + 1}</TableCell>
                                    <TableCell>{user.Email}</TableCell>
                                    <TableCell>{user.UserName}</TableCell>
                                    <TableCell>{user.CreatedAt}</TableCell>
                                    <TableCell>
                                        <ButtonTemplate
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            changetextcolor='#fff'
                                            borderradius='5px'
                                            onClick={() => handleActivate(user)}
                                        >Activate</ButtonTemplate>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
            {users?.Items?.length <= 0 && <Box>
                Confirmation link history not found!
            </Box>}
            {users?.Count?.Inactive > 0 && <Box py={10}>
                <Grid container>
                    <Grid item>
                        {pluralize(users?.Count?.Inactive, 'user', 'users')}
                    </Grid>
                    <Grid item>
                        {pageCount() > 1 && <Box pb={10}>
                            <Pagination count={pageCount()} onChange={(e, value) => setPage(value)} page={page} color="primary"/>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
      </Box>
    )
}

export default UserConfirmation;